import React from 'react';
import ApiHandler from "../../model/ApiHandler";

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.server = props.server;
        this.application = props.application;
        this.state = {
            regions: {},
            loading: false
        };
        this.apiHandler = new ApiHandler();
    }

    render() {
        return (
            <div className="col-sm-6 col-md-6 col-lg-4 full-height">
                <div className="card">
                    <div className="card-header">
                        <h6 className="heading">Admin Credentials</h6>
                        <p className="sub-heading">WordPress Details</p>
                    </div>
                    <div className="card-body server-details-list">
                        <div className="row">
                            <div className="col-2">
                                <img src={require("../../assets/images/icons/server-ram.svg")} alt="" />
                            </div>
                            <div className="col-10"><p>App: {this.application.name}</p></div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <img src={require("../../assets/images/icons/domain.svg")} alt="" />
                            </div>
                            <div onClick={this.props.copyToClipBoard} title="Click to Copy" className="col-10"><p>Domain: {this.application.domain}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <img src={require("../../assets/images/icons/server-ip.svg")} alt="" />
                            </div>
                            <div onClick={this.props.copyToClipBoard} title="Click to Copy" className="col-10">
                                <p style={{ marginRight: '10px', float: 'left' }}>IP: {this.application.server.ip_address}</p>
                                <button style={{ float: 'right',height: '28px',fontSize:'10px' }} className="btn btn-theme btn-xs" onClick={(e) => this.props.copyToClipBoard(e)}>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <img src={require("../../assets/images/icons/server-user.svg")} alt="" />
                            </div>
                            <div className="col-10" onClick={this.props.copyToClipBoard} title="Database Name - Click to Copy">
                                <p style={{ marginRight: '10px', float: 'left' }}>
                                    Username: {this.application.username ? this.application.username : 'Unknown'}
                                </p>
                                <button style={{ float: 'right',height:'28px',fontSize:'10px' }} className="btn btn-theme btn-xs" onClick={(e) => this.props.copyToClipBoard(e)}>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <img src={require("../../assets/images/icons/server-password.svg")} alt="" />
                            </div>
                            <div className="col-10" onClick={this.props.copyToClipBoard} title="Database Password - Click to Copy">
                                <p style={{ marginRight: '10px', float: 'left' }}>
                                    Password: {this.application.password ? this.application.password : 'Unknown'}
                                </p>
                                <button style={{ float: 'right',height:'28px',fontSize:'10px' }} className="btn btn-theme btn-xs" onClick={(e) => this.props.copyToClipBoard(e)}>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <a
                                rel="noopener noreferrer"
                                href={(this.application.ssl_enabled === "1" ? "https://" : 'http://') + this.application.domain + "/wp-login.php"}
                                target="_blank"
                                className="btn btn-theme btn-sm"
                            >
                                Login to WordPress
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Summary;
