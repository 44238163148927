import React from 'react';
import ApiHandler from "../../model/ApiHandler";
import { Alert, Modal, Button } from 'react-bootstrap';
import Loader from '../../components/template/Loader';

class Backups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backups: [],
            showBackup: null,
            loading: true,
            showModal: false,
            showAutoBackupModal: false,
            makingBackup: false,
            restoring: false,
            restoringIndex: null,
            xIndex: null,
            error: "",
            success: "",
            web: null,
            db: null,
            email: null,
            restoreAppId: null,
            dns: null,
            backupDate: "",
            backupTime: ""
        };
        this.apiHandler = new ApiHandler();
    }

    componentDidMount() {
        this.loadBackups();
    }

    handleModalClose = () => {
        this.setState({
            showModal: false,
            restoringIndex: null,
            xIndex: null,
            web: null,
            db: null,
            email: null,
            restoreAppId: null,
            dns: null
        });
    }

    loadBackups = () => {
        this.apiHandler.getAllBackups(this.props.server.id, (msg, data) => {
            if (data && Array.isArray(data)) {
                this.setState({
                    backups: data,
                    loading: false
                });
            } else {
                this.setState({
                    error: "Failed to load backups",
                    loading: false
                });
            }
        });
    }

    getBackup = (xindex, index) => {
        return this.state.backups[xindex][index];
    }

    showBackups = (index) => {
        let bkp = this.state.backups[index];
        if (bkp.length > 0) {
            this.setState({
                showBackup: index,
            });
        }
    }

    renderBackups = () => {
        if (this.state.backups.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="5" className="text-center">No Backups</td>
                </tr>
            );
        }

        return this.state.backups.map((data, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{data[0].domain}</td>
                <td>{data[0].date}</td>
                <td>{data[0].time}</td>
                <td>
                    {data[0].web ? <i className="fa fa-check text-green"></i> : <i className="fa fa-times text-danger"></i>} Website,&nbsp;&nbsp;
                    {data[0].db ? <i className="fa fa-check text-green"></i> : <i className="fa fa-times text-danger"></i>} Database,&nbsp;&nbsp;
                </td>
                <td>
                    <button onClick={() => this.showBackups(index)} className="btn btn-theme btn-sm">
                        {this.state.restoring && this.state.restoringIndex === index ? (
                            <img alt="" src={require("../../assets/images/loading.gif")} style={{ width: "20px", filter: "brightness(20)" }} />
                        ) : 'View'}
                    </button>
                </td>
            </tr>
        ));
    }

    renderDomainBackup = (xindex) => {
        if (this.state.backups[xindex].length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="6" className="text-center">No Backups</td>
                </tr>
            );
        }

        return this.state.backups[xindex].map((data, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.date}</td>
                <td>{data.time}</td>
                <td>
                    {data.web ? <i className="fa fa-check text-green"></i> : <i className="fa fa-times text-danger"></i>} Website,&nbsp;&nbsp;
                    {data.db ? <i className="fa fa-check text-green"></i> : <i className="fa fa-times text-danger"></i>} Database,&nbsp;&nbsp;
                </td>
                <td>{data.size} MB</td>
                <td>
                    <button onClick={() => this.handleModalShow(xindex, data)} className="btn btn-theme btn-sm">
                        {this.state.restoring && this.state.restoringIndex === index ? (
                            <img alt="" src={require("../../assets/images/loading.gif")} style={{ width: "20px", filter: "brightness(20)" }} />
                        ) : 'Restore'}
                    </button>
                </td>
            </tr>
        ));
    }

    dataChange = (event) => {
        let value = event.target.checked;
        this.setState({ [event.target.name]: value });
    }

    handleModalShow = (xindex, data) => {
        if (this.state.restoring) return;
        this.setState({ showModal: true, restoringIndex: data.name, restoreAppId: data.application_id, xIndex: xindex });
    }

    restoreBackup = () => {
        if (!this.checkDisabled()) return;

        this.setState({ restoring: true });

        if (this.props.server.username) {
            let backup_name = this.state.restoringIndex;
            this.apiHandler.restoreBackupShellApp(this.state.restoreAppId, backup_name, (msg, data) => {
                this.setState({
                    restoring: false,
                    showModal: false,
                    success: msg.message,
                    restoringIndex: null,
                    xIndex: null,
                    web: null,
                    db: null,
                    email: null,
                    restoreAppId: null,
                    dns: null
                });
            }, (error) => {
                this.setState({ restoring: false, error: error.message });
            });
            return;
        }

        let index = this.state.restoringIndex;
        let xindex = this.state.xIndex;
        let web = this.state.web ? this.getBackup(xindex, index).domain : 'no';
        let db = this.state.db ? this.getBackup(xindex, index).name : 'no';
        let mail = this.state.email ? this.getBackup(xindex, index).domain : 'no';
        let dns = this.state.dns ? this.getBackup(xindex, index).domain : 'no';

        this.apiHandler.restoreBackup(this.props.server.id, this.getBackup(xindex, index).name, web, db, mail, dns, "no", (msg, data) => {
            this.setState({
                restoring: false,
                showModal: false,
                success: msg.message,
                restoringIndex: null,
                xindex: null,
                restoreAppId: null,
                web: null,
                db: null,
                email: null,
                dns: null
            });
        }, (error) => {
            this.setState({ restoring: false, error: error.message });
        });
    }

    takeBackup = () => {
        this.setState({ makingBackup: true });
        this.apiHandler.createBackups(this.props.server.id, (msg, data) => {
            this.setState({ loading: true, success: msg.message, makingBackup: false });
            this.loadBackups();
        }, (err) => {
            this.setState({ makingBackup: false, error: err.message });
        });
    }

    scheduleAutoBackup = () => {
        const { backupDate, backupTime } = this.state;
        console.log(`Backup Date: ${backupDate}, Backup Time: ${backupTime}`);

        // Vérifier si backupDate et backupTime sont définis
        const [year, month, day] = backupDate ? backupDate.split('-') : ['*', '*', '*'];
        const [hour, minute] = backupTime ? backupTime.split(':') : ['*', '*'];

        const cronYear = year !== undefined ? year : '*';
        const cronMonth = month !== undefined ? month : '*';
        const cronDay = day !== undefined ? day : '*';
        const cronHour = hour !== undefined ? hour : '*';
        const cronMinute = minute !== undefined ? minute : '*';

        const cronExpression = `${cronMinute} ${cronHour} ${cronDay} ${cronMonth} *`;
        console.log(`Cron Expression: ${cronExpression}`);

        this.apiHandler.scheduleAutoBackup(this.props.server.id, cronExpression, (msg, data) => {
            this.setState({ showAutoBackupModal: false, success: msg.message });
        }, (err) => {
            this.setState({ showAutoBackupModal: false, error: err.message });
        });
    }

    setShow = () => {
        this.setState({ error: "", success: "" });
    }

    checkDisabled = () => {
        if (this.props.server.username) {
            return true;
        }

        return this.state.web || this.state.db || this.state.email || this.state.dns;
    }

    render() {
        return (
            <>
                <div className="col-md-12 full-height">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8 align-self-center">
                                    {this.state.showBackup !== null ? (
                                        <>
                                            <h6 className="heading">
                                                <i onClick={() => this.setState({ showBackup: null })} className="fa fa-arrow-left mr-2"></i>
                                                {this.state.backups[this.state.showBackup][0].domain}
                                            </h6>
                                        </>
                                    ) : (
                                        <>
                                            <h6 className="heading">Backups</h6>
                                            <p className="sub-heading">Backups Details</p>
                                        </>
                                    )}
                                </div>
                                <div className="col-4 align-self-center text-right">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button className="btn btn-theme btn-sm" onClick={this.takeBackup} style={{ marginLeft: '10px' }}>
                                            {this.state.makingBackup ? (
                                                <img alt="" src={require("../../assets/images/loading.gif")} style={{ width: "30px", filter: "brightness(20)" }} />
                                            ) : 'Backup All Websites'}
                                        </button>
                                        <button className="btn btn-theme btn-sm" onClick={() => this.setState({ showAutoBackupModal: true })} style={{ marginLeft: '10px' }}>
                                            Schedule Auto Backup
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <Alert onClose={this.setShow} show={!!this.state.error} variant="danger" dismissible>
                                {this.state.error}
                            </Alert>
                            <Alert onClose={this.setShow} show={!!this.state.success} variant="success" dismissible>
                                {this.state.success}
                            </Alert>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    {this.state.showBackup !== null ? (
                                        <>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Backups</th>
                                            <th>Size</th>
                                            <th>Action</th>
                                        </>
                                    ) : (
                                        <>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Backups</th>
                                            <th>Action</th>
                                        </>
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="6">
                                            <Loader type="card" />
                                        </td>
                                    </tr>
                                ) : this.state.showBackup !== null ? (
                                    this.renderDomainBackup(this.state.showBackup)
                                ) : (
                                    this.renderBackups()
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal centered show={this.state.showModal} onHide={this.handleModalClose}>
                    <form action="#" method="post">
                        <Modal.Header closeButton>
                            <Modal.Title>Restore Backup</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.restoringIndex !== null && (
                                <div>
                                    {this.props.server.username ? (
                                        <div className="modal-form mb-3">
                                            <label>
                                                Restoring backups will cause overwriting existing code.
                                                <br />
                                                <strong>Are you sure about this?</strong>
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="modal-form mb-3">
                                            <label>Select backups to restore</label>
                                            <div className="custom-control custom-checkbox">
                                                <input name="web" onChange={this.dataChange} type="checkbox" className="custom-control-input" id="web" />
                                                <label className="custom-control-label" htmlFor="web">Restore Website</label>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input name="db" onChange={this.dataChange} type="checkbox" className="custom-control-input" id="db" />
                                                <label className="custom-control-label" htmlFor="db">Restore Database</label>
                                            </div>
                                        </div>
                                    )}
                                    <p>Restoring backups from <i>{this.state.restoringIndex}</i></p>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={this.handleModalClose}>Close</Button>
                            <Button className="btn btn-theme" onClick={this.restoreBackup}>
                                {this.state.restoring ? (
                                    <img alt="" src={require("../../assets/images/loading.gif")} style={{ width: "20px", filter: "brightness(20)" }} />
                                ) : 'Restore'}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <Modal centered show={this.state.showAutoBackupModal} onHide={() => this.setState({ showAutoBackupModal: false })}>
                    <form action="#" method="post">
                        <Modal.Header closeButton>
                            <Modal.Title>Schedule Auto Backup</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-form mb-3">
                                <label htmlFor="backupDate">Date</label>
                                <input
                                    type="date"
                                    name="backupDate"
                                    className="form-control custom-date-time-input"
                                    onClick={(e) => e.target.showPicker()}
                                    onChange={(e) => this.setState({ backupDate: e.target.value })}
                                />
                            </div>
                            <div className="modal-form mb-3">
                                <label htmlFor="backupTime">Time</label>
                                <input
                                    type="time"
                                    name="backupTime"
                                    className="form-control custom-date-time-input"
                                    onClick={(e) => e.target.showPicker()}
                                    onChange={(e) => this.setState({ backupTime: e.target.value })}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" onClick={() => this.setState({ showAutoBackupModal: false })}>Close</Button>
                            <Button className="btn btn-theme" onClick={this.scheduleAutoBackup}>Schedule</Button>
                        </Modal.Footer>
                    </form>
                </Modal>            </>
        );
    }
}

export default Backups;
